import React from "react"
import { navigate } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/SEO"

import * as contentStyle from "./content.module.scss"

const ContentPage = () => {
  return (
    <Layout>
      <SEO
        title="Contents"
        description="Different topics available in The Octet Institute"
      />

      <div className={contentStyle.contentLayout}>
        <aside></aside>
        <div>
          <h1>Contents</h1>
          <div className={contentStyle.cardLayout}>
            <div
              role="button"
              className={contentStyle.cardItem}
              onClick={() => {
                navigate("/content/verilog/introduction/")
              }}
              aria-label="go to verilog contents"
            >
              Verilog
              <div className={contentStyle.description}>
                Verilog is the most commonly used HDL. Learn more about Verilog
                in this series.
              </div>
            </div>
            <div
              role="button"
              //   disabled
              className={contentStyle.cardItem}
              onClick={() => {
                navigate("/content/sv/introduction/")
              }}
              aria-label="go to system verilog contents"
            >
              System Verilog
              <div className={contentStyle.description}>
                System Verilog is commonly used for verification. Learn more
                about System Verilog in this series.
              </div>
            </div>

            <div
              role="button"
              className={contentStyle.cardItem}
              onClick={() => {
                navigate("/content/uvm/introduction/")
              }}
              aria-label="go to UVM contents"
            >
              UVM
              <div className={contentStyle.description}>
                UVM is a verification methodology based on SV. Learn more about
                UVM in this series.
              </div>
            </div>

            {/* <Link to="/content/verilog/introduction/">
          <div className={contentStyle.cardItem}>
            <h1>Verilog</h1>
          </div>
        </Link>

        <Link to="/content/system-verilog/introduction/">
          <div className={contentStyle.cardItem}>
            <h1>System Verilog</h1>
          </div>
        </Link> */}

            {/* <Link>
          <div className={contentStyle.cardItem}>
            <p>UVM</p>
          </div>
        </Link>

        <Link>
          <div className={contentStyle.cardItem}>
            <p>Python</p>
          </div>
        </Link>

        <Link>
          <div className={contentStyle.cardItem}>
            <p>Linux</p>
          </div>
        </Link> */}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ContentPage
